.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.home-header {
  position: relative;
  background-image: url('../Image/home_page_background.jpg');
  background-size: cover;
  background-position: center;
  padding: 60px 20px;
  text-align: center;
  color: white;
}

.home-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2); /* Increased opacity for better readability */
  z-index: 1;
}

.header-content {
  position: relative;
  z-index: 2;
  max-width: 600px;
  margin: 0 auto;
}

.services-offered,
.why-choose-us,
.get-in-touch {
  margin: 60px 0;
  text-align: center; /* Center align the content */
}

.services-list {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.service {
  flex: 1;
  background: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.why-choose-us ul {
  list-style: none;
  padding: 0;
  margin: 0 auto; /* Center the list */
  display: inline-block; /* Align the bullet points with the text */
  text-align: left; /* Align the text to the left */
}

.why-choose-us li {
  position: relative;
  padding-left: 20px; /* Space for bullet points */
  margin-bottom: 10px;
}

.why-choose-us li::before {
  content: '•';
  position: absolute;
  left: -20px; /* Position bullet points to the left of the text */
  top: 50%;
  transform: translateY(-50%);
  color: #0056b3; /* Match the color with the button */
  font-size: 1em; /* Match the font size with the list */
}

.contact-button {
  background-color: #0056b3;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #003d82;
}

/* Responsive design */
@media (max-width: 1024px) {
  .services-list {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .home-container {
    padding: 10px;
  }

  .home-header {
    padding: 40px 10px;
  }

  .header-content {
    max-width: 100%;
  }

  .why-choose-us ul {
    text-align: center;
  }

  .why-choose-us li::before {
    left: 50%; /* Center bullet points on smaller screens */
    transform: translateX(-50%) translateY(-50%);
  }
}