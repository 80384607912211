.calendar-container {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.calendar>thead>th,
.calendar>td {
  text-align: center;
  padding: 10px;
}

.calendar>tbody>th {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #5f6368;
}

.calendar>tbody>td {
  font-size: 14px;
  color: #202124;
  vertical-align: top;
  position: relative;
  border-right: 1px solid #f1f3f4;
  border-bottom: 1px solid #f1f3f4;
}

.calendar>tbody>td:last-child {
  border-right: none;
}

.calendar>tbody>tr {
  height: 10vh;
  /* set height to 10vh */
}

.current-month {
  background-color: #fff;
}

.calendar-prev-month,
.calendar-next-month {
  color: #ccc;
}

.calendar-weekend {
  background-color: #f8f8f8;
  /* set to lighter shade */
}

.calendar-events {
  position: absolute;
  max-height: 300px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px;
}

.calendar-event {
  margin-bottom: 5px;
  padding: 5px;
  background-color: #267dff;
  color: lightgrey;
  border-radius: 2px;
  cursor: pointer;
}

.calendar-event:hover {
  background-color: #0058af;
  color: white;
}

.calendar-event .time {
  font-weight: 500;
  margin-right: 5px;
}

.calendar-event ,.calendar-title {
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}