.Theme {
  background-color: #007bff;
}

.Theme .navbar-brand,
.Theme .nav-link,
.Theme .dropdown-item,
.Theme .navbar-toggler-icon {
  color: #fff;
}

.Theme .navbar-nav .nav-link:hover,
.Theme .navbar-nav .nav-link:focus,
.Theme .navbar-nav .dropdown-item:hover,
.Theme .navbar-nav .dropdown-item:focus {
  color: #ccc;
}

.Theme .dropdown-menu {
  background-color: #007bff;
}

.Theme .dropdown-menu .dropdown-item {
  color: #fff;
}

.Theme .dropdown-menu .dropdown-item:hover,
.Theme .dropdown-menu .dropdown-item:focus {
  background-color: #0056b3;
}

.Theme .dropdown-menu .dropdown-item.active {
  background-color: #0056b3;
}

.Theme .navbar-toggler {
  border-color: #fff;
}

.dropdown-menu.show {
  right: 0px;
  left: auto !important;
}